// @ngInject
export default function demoService(urls, $http, $q) {
    return {
        createSalesDemo(data) {
            return $http({
                url: `${urls.base}/ra/class/demo`,
                method: 'POST',
                data,
            })
                .then(res => res.data)
                .catch(rej => $q.reject(rej.data));
        },
    };
}
