import _ from 'lodash';

class AdminEditAccountController {
    // @ngInject
    constructor($state, eventMediator, userAccountService, $q) {
        this.$state = $state;
        this.eventMediator = eventMediator;
        this.userAccountService = userAccountService;
        this.$q = $q;

        this.originalTarget = _.cloneDeep(this.targetUser);
        this.targetUser = this.targetUser;

        this.useEmail = !this.targetUser.username;
        this.showUsername = !!this.showUsername;
    }

    $onInit() {
        // TODO update teacher and student account viewing to use group rather than school
        if (this.group || !this.schoolId) return;
        this.group = {
            type: 'school',
            id: this.schoolId,
        };

        if (this.showSchoolAdminInput) {
            this.isSchoolAdministrator = this.userHasAdminRoleInSchool();
        }
    }

    navigationHandler() {
        return this.$q((resolve, reject) => {
            if (angular.equals(this.targetUser, this.originalTarget)) {
                reject();
            }

            resolve(
                'Your edits haven’t been saved. If you leave this page, you will lose these changes.'
            );
        });
    }

    getStateParams(params) {
        return _.assign(
            {
                [`${this.group.type}Id`]: this.group.id,
                identityId: this.targetUser.identity_id,
            },
            params
        );
    }

    _goToAccountDetail(state) {
        // $state.go with option {reload: true} isn't working to repopulate the target user with updated data
        // the targetUserOverride parameter is being used as a hack (hopefully only short-term)
        const stateParams = this.getStateParams({ targetUserOverride: this.targetUserOverride });
        this.$state.go(state, stateParams, { reload: true });
    }

    getCancelUISref() {
        return `${this.cancelState}(${JSON.stringify(this.getStateParams())})`;
    }

    _handleSuccess() {
        const message = `You have successfully updated this account!`;
        this.eventMediator.emit('messageNotification', {
            type: 'success',
            message,
            postStateChange: true,
        });

        this.originalTarget = _.cloneDeep(this.targetUser);
        this.targetUserOverride = this.originalTarget;

        this._goToAccountDetail(this.successState);
    }

    _getValidationMessages() {
        return {
            'User with username exists': {
                field: 'username',
                key: 'duplicate',
                message:
                    'Sorry! There is an existing account that uses this username. Please enter a different username.',
            },
            'User with email exists': {
                field: 'email',
                key: 'duplicate',
                message:
                    'Sorry! There is an existing account that uses this email. Please enter a different email address.',
            },
            unknown: {
                message: 'There was an error updating this student. Please try again.',
            },
        };
    }

    _handleError(data) {
        let messages = this._getValidationMessages();

        let errors;
        if (data && data.errors) {
            errors = _.map(data.errors, e => {
                return messages[e.title];
            });
            errors = _.compact(errors);
        }

        if (!errors || !errors.length) {
            errors = [messages.unknown];
        }

        // Validation Alerts
        _(errors)
            .filter('message')
            .map('message')
            .forEach(message => {
                this.eventMediator.emit('messageNotification', { type: 'danger', message });
            });

        // Trigger inline validation messages
        _(errors)
            .filter('field')
            .forEach(e => {
                const validationFunc = _.get(this, `form.${e.field}.$setValidity`, () => {});

                validationFunc(e.key, false);
            });

        this.form.showErrors = true;
    }

    _clearValidationErrors() {
        let messages = this._getValidationMessages();
        _.forEach(messages, e => {
            if (e.field && this.form[e.field]) {
                this.form[e.field].$setValidity(e.key, true);
            }
        });
    }

    save() {
        this._clearValidationErrors();

        const formData = Object.assign({}, this.targetUser, {
            school_id: this.schoolId,
            is_school_administrator: this.isSchoolAdministrator,
        });

        if (this.form.$valid) {
            return this.userAccountService
                .updateUserAccount(formData)
                .then(this._handleSuccess.bind(this))
                .catch(this._handleError.bind(this));
        } else {
            const message = 'Please complete all required fields.';
            this.eventMediator.emit('messageNotification', { type: 'danger', message });
            this.form.showErrors = true;
        }
    }

    cancel() {
        this.targetUserOverride = _.cloneDeep(this.originalTarget);
        this._goToAccountDetail(this.cancelState);
    }

    userHasAdminRoleInSchool() {
        const adminMemberships = _.get(this.targetUser, 'memberships.Administrator') || [];
        return _.some(
            adminMemberships,
            m => m.group_type === 'Institution' && m.id == this.schoolId
        );
    }
}

const adminEditAccountComponent = {
    templateUrl: 'admin/school/components/admin-edit-account/admin-edit-account.html',
    controller: AdminEditAccountController,
    bindings: {
        label: '@',
        cancelState: '@',
        successState: '@',
        showUsername: '@',
        schoolId: '<',
        schoolName: '<',
        showSchoolAdminInput: '<',
        targetUser: '<',
        group: '<',
    },
};

export default adminEditAccountComponent;
